<template>
    <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
      <HeaderLogin />
      <BtnBack /> 

  
      <!-- ข้อมูลการแข่งขัน -->
      <div v-if="dataLoaded" class="bg-[#171e79] text-white p-4 rounded-lg mb-4 border border-yellow-400">
        <h1 class="text-center text-2xl font-bold mb-4">{{ league }}</h1>
  
        <!-- ทีมและเวลาการแข่ง -->
        <div class="flex justify-between items-center mb-4">
          <div class="text-center">
            <img
              v-if="team1Logo" 
              :src="getLogoPath(team1Logo)"
              alt="Team 1 Logo"
              class="w-16 h-16 mx-auto mb-2"
            />
            <p>{{ team1 }}</p>
          </div>
  
          <div class="text-center text-yellow-400">
            <p>{{ date }}</p>
            <p class="text-2xl font-bold">{{ time }}</p>
          </div>
  
          <div class="text-center">
            <img
              v-if="team2Logo"  
              :src="getLogoPath(team2Logo)"
              alt="Team 2 Logo"
              class="w-16 h-16 mx-auto mb-2"
            />
            <p>{{ team2 }}</p>
          </div>
        </div>
  
        <!-- รูปสนามบอล -->
        <div class="text-center">
          <img
            src="/assets/logoTeam/สนามบอล.png"
            alt="Football Field"
            class="w-64 h-auto mx-auto mb-4"
          />
        </div>
  
        <!-- เลือกทีมทายผล -->
        <h2 class="text-center text-lg font-bold mb-4">เลือกทีมทายผล</h2>
        <div class="flex justify-center mb-4">
          <button
            :class="{'bg-yellow-400': selectedTeam === team1, 'bg-gray-400': selectedTeam !== team1}"
            class="text-black px-6 py-2 rounded-lg font-bold text-sm mx-2"
            @click="selectTeam(team1)"
          >
            {{ team1 }}
          </button>
          <button
            :class="{'bg-yellow-400': selectedTeam === team2, 'bg-gray-400': selectedTeam !== team2}"
            class="text-black px-6 py-2 rounded-lg font-bold text-sm mx-2"
            @click="selectTeam(team2)"
          >
            {{ team2 }}
          </button>
        </div>
  
        <!-- ปุ่มทายผลบอล -->
        <div class="text-center">
          <button
            @click="submitPrediction"
            class="bg-yellow-400 text-black px-6 py-2 rounded-lg font-bold text-lg"
          >
            ทายผลบอล
          </button>
        </div>
  
        <!-- แจ้งเตือน -->
        <div v-if="notification" class="text-center text-green-500 mt-4">
          {{ notification }}
        </div>
      </div>
  
      <!-- แสดงข้อความเมื่อไม่สามารถโหลดข้อมูลได้ -->
      <div v-else class="text-center text-white">
        <h2>ไม่สามารถโหลดข้อมูลได้</h2>
      </div>

      <!-- เงื่อนไขการเข้าร่วมกิจกรรม Warning -->
    <div class="bg-[#fff5e4] text-[#815b2a] p-4 mt-6 rounded-lg">
      <h3 class="text-lg font-bold mb-2">
        เงื่อนไขการเข้าร่วมกิจกรรม รับเครดิตจากการทายผลบอล
      </h3>
      <ul class="list-decimal list-inside text-sm">
        <li>คุณพี่จะต้องฝากเงินขั้นต่ำ 500 บาท เพื่อมีสิทธิ์ทายผลสกอร์</li>
        <li>ต้องมียอดฝาก 500 บาท ถึงได้ 1 สิทธิ์แรก</li>
        <li>คุณพี่ฝากมา 500 บาท ได้ 1 สิทธิ์ในการทายผล 1 คู่ 500/1 สิทธิ์</li>
        <li>คุณพี่ฝากมา 1,000 บาท ได้ 2 สิทธิ์ในการทายผล 2 คู่</li>
        <li>รับรางวัลเป็นเครดิตทำเทิร์น 1 เท่า ถอนได้ไม่อั้น*</li>
      </ul>
    </div>
    </div>
  </template>
  
  <script>
  import HeaderLogin from "@/components/HeaderLogin.vue";
  import BtnBack from "@/components/BtnBack.vue"; 
  
  export default {
    name: "FootballDetail",
    components: {
      HeaderLogin,
      BtnBack,
    },
    data() {
      let match = null;
      try {
        match = JSON.parse(localStorage.getItem('selectedMatch'));
      } catch (error) {
        console.error("Error loading match data from localStorage:", error);
      }
  
      return {
        dataLoaded: match !== null,
        league: match?.league || 'ไม่ทราบลีก',
        date: match?.date || 'ไม่ทราบวันที่',
        time: match?.time || 'ไม่ทราบเวลา',
        team1: match?.team1 || 'ไม่ทราบทีมที่ 1',
        team2: match?.team2 || 'ไม่ทราบทีมที่ 2',
        team1Logo: match?.team1Logo || null,
        team2Logo: match?.team2Logo || null,
        selectedTeam: null, // เก็บข้อมูลทีมที่ผู้ใช้เลือก
        notification: null, // สำหรับแสดงข้อความแจ้งเตือน
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      getLogoPath(logo) {
        return logo ? `/assets/logoTeam/${logo}` : '';
      },
      selectTeam(team) {
        this.selectedTeam = team; // บันทึกทีมที่ผู้ใช้เลือก
      },
      submitPrediction() {
        if (this.selectedTeam) {
          this.notification = `คุณได้เลือกทีม ${this.selectedTeam} ในการทายผล`;
        } else {
          this.notification = 'กรุณาเลือกทีมก่อนทายผล';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .material-icons {
    font-size: 24px;
  }
  </style>
  