<template>
  <div class="bg-black overflow-hidden whitespace-nowrap  md:max-w-[670px] mx-auto">
    <p class="animate-marquee text-white font-bold text-lg">
      MARUAY61 สุดยอดเว็บสล็อตอันดับ 1 ในไทย ที่ดีที่สุด ผ่านระบบอัตโนมัติ
      อยู่ที่ไหนก็สามารถเล่นได้ เล่นผ่านมือถือได้ทุกระบบ สมัครบาคาร่า, เล่นง่ายๆ
      ยืนยัน OTP 10 วินาที ฝาก - ถอน ระบบอัตโนมัติ ไม่ต้องแจ้งสลิป
    </p>
  </div>
</template>

<script>
export default {
  name: "Textarea",
};
</script>

<style scoped>
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  display: inline-block;
  animation: marquee 25s linear infinite;
}
</style>
