<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <HeaderLogin />
    <BtnBack/>

    <!-- SliderBoxingView -->
    <SliderBoxingView />

    <!-- รายการนักมวยที่มีไอคอน fire.gif พร้อมปุ่มลิงก์ไปยังประวัติทายมวย -->
    <div class="flex items-end justify-between mb-4 mt-5">
      <div class="flex items-center">
        <img src="/assets/icons/fire.gif" alt="fire icon" class="w-8 h-8 mr-2" />
        <h1 class="text-2xl font-bold text-white">รายการนักมวย</h1>
      </div>

      <!-- ปุ่มลิงก์ไปยังหน้า BoxingHistory.vue พร้อมไอคอน -->
      <button @click="goToHistory" class="text-white text-sm font-bold flex items-center hover:underline">
        <span class="material-icons mr-2">history</span>
        ประวัติทายมวย
      </button>
    </div>

    <!-- Tabs ที่สามารถ Scroll ซ้ายขวาได้ -->
    <div class="flex overflow-x-auto whitespace-nowrap mb-4 space-x-4">
      <button class="px-4 py-2 bg-white text-black rounded-lg">
        Heavyweight
      </button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">
        Middleweight
      </button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">Lightweight</button>
    </div>

    <!-- รายการทายมวย -->
    <div v-for="(match, index) in matchList" :key="index" class="bg-[#171e79] text-white p-4 rounded-lg mb-4 border border-yellow-400">
      <h2 class="text-center text-lg font-bold mb-2">{{ match.league }}</h2>

      <!-- นักมวยและเวลาการแข่ง -->
      <div class="flex justify-between items-center mb-4">
        <div class="text-center">
          <img :src="getLogoPath(match.fighter1Logo)" alt="Fighter 1 Logo" class="w-16 h-16 mx-auto mb-2" />
          <p>{{ match.fighter1 }}</p>
        </div>

        <div class="text-center text-yellow-400">
          <p>{{ match.date }}</p>
          <p class="text-2xl font-bold">{{ match.time }}</p>
        </div>

        <div class="text-center">
          <img :src="getLogoPath(match.fighter2Logo)" alt="Fighter 2 Logo" class="w-16 h-16 mx-auto mb-2" />
          <p>{{ match.fighter2 }}</p>
        </div>
      </div>

      <!-- ปุ่มทายมวย -->
      <div class="text-center flex justify-center">
        <button @click="goToDetail(match)" class="bg-yellow-400 text-black px-6 py-2 rounded-lg font-bold text-lg flex items-center justify-center">
          <img src="/assets/icons/play-icon.png" alt="play icon" class="w-5 h-5 mr-2" />
          ทายมวย
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import BtnBack from "@/components/BtnBack.vue";
import HeaderLogin from "@/components/HeaderLogin.vue";
import SliderBoxingView from "@/components/SliderBoxingView.vue";

export default {
  name: "BoxingView",
  components: {
    HeaderLogin,
    SliderBoxingView,
    BtnBack,
  },
  data() {
  return {
    matchList: [
      {
        league: "Heavyweight Championship",
        date: "17/10/2024",
        time: "02:00",
        fighter1: "Nong-O Gaiyanghadao", // แก้ชื่อนักมวยคนที่ 1
        fighter2: "Alaverdi Ramazanov",  // แก้ชื่อนักมวยคนที่ 2
        fighter1Logo: "nong-o-gaiyanghadao-logo.png",  // แก้ไฟล์รูปภาพนักมวยคนที่ 1
        fighter2Logo: "alaverdi-ramazanov-logo.png",   // แก้ไฟล์รูปภาพนักมวยคนที่ 2
      },
      {
        league: "Middleweight Championship",
        date: "18/10/2024",
        time: "04:00",
        fighter1: "Rodtang Jitmuangnon",  // แก้ชื่อนักมวยคนที่ 1
        fighter2: "Joseph Lasiri",        // แก้ชื่อนักมวยคนที่ 2
        fighter1Logo: "rodtang-jitmuangnon-logo.png",  // แก้ไฟล์รูปภาพนักมวยคนที่ 1
        fighter2Logo: "joseph-lasiri-logo.png",        // แก้ไฟล์รูปภาพนักมวยคนที่ 2
      },
    ],
  };
},


  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToDetail(match) {
      localStorage.setItem("selectedMatch", JSON.stringify(match));
      this.$router.push({ name: "BoxingDetail" });
    },
    goToHistory() {
      this.$router.push({ name: "BoxingHistory" });
    },
    getLogoPath(logo) {
      return `/assets/ImagesBoxing/${logo}`;
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}
</style>
