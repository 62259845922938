const BASE_URL = process.env.VUE_APP_API_URL || "http://localhost:5005";

export default {
    LOGIN: `${BASE_URL}/api/users/login`,
    SendNotification: `${BASE_URL}/api/users/notify`,
    CHECK_USER: `${BASE_URL}/api/users/checkUser`,
    REGISTER: `${BASE_URL}/api/users/register`,


    // เพิ่มสำหรับ จัดกานเมนู Rank

    GET_RANKINGS: `${BASE_URL}/api/rankings`,

    UPLOAD_FILE: `${BASE_URL}/api/users/upload`, // Endpoint for uploading files
    GET_UPLOAD_BY_ID: (userId) => `${BASE_URL}/api/birthday/uploads/${userId}`,
    // API สำหรับดึงข้อมูลกิจกรรมวันเกิดทั้งหมด
    GET_BIRTHDAY_PROMOTIONS: `${BASE_URL}/api/birthday/birthday-pages`,

    // สำหรับจัดการเมนู
    GET_MENUS: `${BASE_URL}/api/menus/getmenu`, // ดึงข้อมูลเมนู
    ADD_MENU: `${BASE_URL}/api/menus/addmenu`, // เพิ่มเมนูใหม่
    UPDATE_MENU: (menuId) => `${BASE_URL}/api/menus/${menuId}`, // อัปเดตสถานะเมนู
    DELETE_MENU: (menuId) => `${BASE_URL}/api/menus/${menuId}`, // ลบเมนู
    // เพิ่มสำหรับ lottery

    CREATE_LOTTERY_GUESS: `${BASE_URL}/api/lottery/guess`,
    // GET_LOTTERY_HISTORY: (username) => `${BASE_URL}/api/lottery/history/${username}`,
    ACTIVE_ROUND_AND_CREDIT: (username) => `${BASE_URL}/api/lottery/active-round-and-credit${username ? `?username=${username}` : ''}`,
    GET_LOTTERY_HISTORY: (username) => `${BASE_URL}/api/lottery/user-history?username=${username}`, // เพิ่มเส้นทางนี้
};
