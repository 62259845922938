<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <HeaderLogin />
    <BtnBack />

    <!-- Birthday Promotion Section -->
    <div class="bg-[#fff5e4] text-[#815b2a] p-4 rounded-lg shadow-lg mb-6" v-if="promotionData">
      <h2 class="text-black text-2xl font-bold mb-4">{{ promotionData.title }}</h2>
      <h3 class="text-center text-lg font-bold">
        {{ promotionData.description }}
      </h3>
      <img :src="promotionData.imageUrl" alt="โปรวันเกิด" class="w-full h-auto my-4" />
      <p class="font-bold mb-2">เงื่อนไขการเข้าร่วมกิจกรรม</p>
      <ul class="list-disc ml-6 text-sm">
        <li v-for="(condition, index) in promotionData.conditions" :key="index">{{ condition }}</li>
      </ul>
    </div>

    <!-- Notify Birthday Button -->
    <div class="flex justify-center items-center">
      <button v-if="!showUpload" @click="handleNotify"
        class="bg-yellow-400 px-4 py-2 rounded-lg text-black font-bold text-lg flex items-center justify-center zoom-animation">
        <img src="@/assets/icons/birthday-cake.png" alt="Birthday Icon" class="w-6 h-6 mr-2" />
        แจ้งวันเกิด
      </button>

      <!-- File Upload Section -->
      <div v-if="showUpload" class="mt-6">
        <label class="block mb-2 text-sm font-bold text-white">อัปโหลดไฟล์บัตรประชาชน</label>
        <input type="file" @change="handleFileUpload"
          class="block w-full text-sm text-gray-500 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none" />
        <!-- Confirm Upload Button -->
        <button @click="confirmUpload" class="bg-yellow-400 px-4 py-2 rounded-lg text-black font-bold text-lg mt-4">
          อัปโหลด
        </button>

        <!-- Upload Status -->
        <!-- <div class="mt-4">
          <p class="text-white font-bold">
            สถานะ: <span class="text-yellow-400">{{ uploadStatus }}</span>
          </p>
        </div> -->
      </div>
    </div>

    <!-- Upload History Table -->
    <div class="overflow-x-auto mt-10">
      <table class="w-full bg-[#171e79] text-white p-4 rounded-lg table-auto border-collapse">
        <thead class="bg-yellow-400 text-black">
          <tr>
            <th class="px-4 py-2 text-left">วันที่</th>
            <th class="px-4 py-2 text-left">สถานะ</th>
            <th class="px-4 py-2 text-left">เพิ่มเครดิต</th>
            <th class="px-4 py-2 text-left">รูปภาพ</th> <!-- เพิ่มคอลัมน์รูปภาพ -->
          </tr>
        </thead>
        <tbody>
          <tr v-if="historyList.length === 0">
            <td colspan="4" class="text-center py-4 text-yellow-400">
              ยังไม่มีข้อมูลการแจ้งวันเกิด
            </td>
          </tr>
          <tr v-for="(history, index) in historyList" :key="index" class="border-b border-yellow-500">
            <td class="px-4 py-2">{{ history.date }}</td>
            <td :class="{
              'text-green-500': history.status === 'อนุมัติแล้ว',
              'text-red-500': history.status === 'ปฏิเสธ',
              'text-yellow-400': history.status === 'รอดำเนินการ',
            }" class="px-4 py-2">
              {{ history.status }}
            </td>
            <td class="px-4 py-2">{{ history.credit }} บาท</td>
            <!-- คอลัมน์แสดงรูปภาพ -->
            <td class="px-4 py-2">
              <!-- ตรวจสอบว่า imageUrl มีค่า ถ้ามีจะแสดงรูปภาพ, ถ้าไม่มีจะแสดงข้อความ "ไม่มีรูป" -->
              <a v-if="history.imageUrl" :href="history.imageUrl" target="_blank">
                <img :src="history.imageUrl" alt="Uploaded Image" class="w-12 h-12 object-cover cursor-pointer" />
              </a>
              <span v-else>ไม่มีรูป</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import BtnBack from "@/components/BtnBack.vue";
import HeaderLogin from "@/components/HeaderLogin.vue";
import API_PATH from "@/config/apiPath";
import axiosInstance from "@/config/axios";

export default {
  components: {
    HeaderLogin,
    BtnBack,
  },
  data() {
    return {
      showUpload: false,
      uploadStatus: "รอดดำเนินการ",
      historyList: [], // List to hold the upload history including image URLs
      file: null,
      promotionData: null, // store promotion data
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.userId; // ใช้ Vuex ดึง userId
    },
    username() {
      return this.$store.state.user.username; // ใช้ Vuex ดึง username
    },
    phoneNumber() {
      return this.$store.state.user.phoneNumber; // ใช้ Vuex ดึง phoneNumber
    },
  },
  methods: {
    // ฟังก์ชันแสดง toast
    showToast(message, type = "info") {
      this.$toast.open({
        message,
        type, // Type will be info, success, error based on the status
        position: "bottom-left",
        duration: 5000,
        style: {
          backgroundColor: type === "success" ? "#28a745" : "#ffc107",
          color: "black",
        },
        className: "custom-toast",
      });
    },

    async fetchBirthdayPromotions() {
  try {
    const response = await axiosInstance.get(API_PATH.GET_BIRTHDAY_PROMOTIONS);
    // console.log('Response Data:', response.data); // Log response for debugging

    if (response.data && response.data.length > 0) {
      // Access the first element of the array
      const promotion = response.data[0];

      // Update promotionData with the values from the response
      this.promotionData = {
        title: promotion.title,
        description: promotion.description,
        imageUrl: promotion.imageUrl,
        // Split conditions by \r\n and filter out any empty values
        conditions: promotion.conditions[0].split('\r\n').filter(condition => condition.trim() !== '')
      };
    } else {
      console.warn('No promotion data available');
    }
  } catch (error) {
    console.error('Error fetching birthday promotions:', error);
    alert('เกิดข้อผิดพลาดในการดึงข้อมูลโปรโมชัน');
  }
},


    // ฟังก์ชันดึงประวัติการอัปโหลด
    async fetchUploadHistory() {
      let userId = this.userId || this.username || this.phoneNumber;

      if (!userId) {
        console.error("User ID, username หรือ phoneNumber is missing.");
        return;
      }

      try {
        const response = await axiosInstance.get(API_PATH.GET_UPLOAD_BY_ID(userId));

        // console.log("API Response:", response);

        if (response.data && Array.isArray(response.data)) {
          // Set the history list with the uploaded image data
          this.historyList = response.data.map((upload) => {
            let formattedDate = "ไม่ระบุวันที่";

            if (upload.uploadDate) {
              const date = new Date(upload.uploadDate);
              formattedDate = date.toLocaleString("th-TH", {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
              }).replace(/\//g, '/').replace(",", ""); // Formatted as "yyyy/mm/dd HH:MM:SS"
            }

            return {
              date: formattedDate,
              status: upload.status || "รอดดำเนินการ",
              credit: upload.creditAdded || 0,
              imageUrl: upload.fileUrl || null, // Add fileUrl to the history list
            };
          });
        } else {
          // console.warn("No data returned from the API.");
          this.historyList = [];
        }
      } catch (error) {
        // console.error("Error fetching upload history:", error.response?.data || error.message);
        alert("เกิดข้อผิดพลาดในการดึงข้อมูล");
      }
    },


    handleNotify() {
      this.showUpload = true;
    },

    // ฟังก์ชันสำหรับจัดการไฟล์ที่เลือก
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    // ฟังก์ชันที่ยืนยันการอัปโหลดไฟล์
    async confirmUpload() {
      if (!this.file) {
        alert("กรุณาเลือกไฟล์ก่อนอัปโหลด");
        return;
      }

      let userId = this.userId || this.username || this.phoneNumber;

      if (!userId) {
        alert("ไม่พบข้อมูลผู้ใช้ กรุณาล็อกอินอีกครั้ง");
        return;
      }

      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("userId", userId);

      try {
        const response = await axiosInstance.post(API_PATH.UPLOAD_FILE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        this.uploadStatus = "รอดดำเนินการ";
        this.fetchUploadHistory(); // Refresh the history after successful upload

        // แสดง Toast แจ้งเตือนสำเร็จ
        this.showToast("อัปโหลดสำเร็จ!", "success");

        // Instead of opening the image, just update the historyList with the image URL
        const fileUrl = response.data.url;
        this.addImageToHistory(fileUrl); // Add to the history list

      } catch (error) {
        // console.error("Error uploading file:", error);
        alert("เกิดข้อผิดพลาดในการอัปโหลด");

        // แสดง Toast แจ้งเตือนข้อผิดพลาด
        this.showToast("เกิดข้อผิดพลาดในการอัปโหลด", "error");
      }
    },

    // ฟังก์ชันที่เพิ่มรูปที่อัปโหลดไปยังประวัติการอัปโหลด
    addImageToHistory(imageUrl) {
      this.historyList.push({
        date: new Date().toLocaleDateString(),
        status: "รอดดำเนินการ",
        credit: 0,
        imageUrl, // Save the URL of the uploaded image
      });
    },

    // ฟังก์ชันที่ใช้แสดงรูปในแท็บใหม่
    showImage(imageUrl) {
      const newWindow = window.open(imageUrl, '_blank');
      newWindow.focus();
    }
  },
  async mounted() {
    // console.log("Mounted User ID:", this.userId);
    if (!this.userId && !this.username && !this.phoneNumber) {
      // console.error("User ID, username หรือ phoneNumber is not available. Please ensure the user is logged in.");
      return;
    }
    await this.fetchUploadHistory();
    await this.fetchBirthdayPromotions(); // ดึงข้อมูลอัปโหลดเมื่อ component ถูก mount
  },
};
</script>




<style scoped>
h2 {
  text-align: center;
}

img {
  border-radius: 8px;
}

input[type="file"] {
  padding: 0.5rem;
}

table {
  border-spacing: 0;
}

th,
td {
  border-bottom: 1px solid #ffdd57;
}

th {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ffdd57;
}

td {
  text-align: left;
  padding: 12px 16px;
}

tr:hover {
  background-color: #1e2a7a;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.zoom-animation {
  animation: zoomInOut 2s ease-in-out infinite;
}
</style>