// main.ts
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import 'material-icons/iconfont/material-icons.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App);

app.use(store) // ใช้ store ที่เชื่อมมาจาก store/index.ts
    .use(router)
    .use(VueToast)
    .mount('#app');
