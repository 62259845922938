<template>
  <div class="main-container">
    <!-- Header -->
    <HeaderLogin class="header-container" :username="username" />
    <!-- Other components -->
    <Textarea />
    <MenuList />
    <router-view /> 
    <GameList />
  </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import Textarea from "@/components/Textarea.vue";
import MenuList from "@/components/MenuList.vue";
import GameList from "@/components/GameList.vue";
import { mapState } from 'vuex';

export default {
  name: "MainLayoutAfterlogin",
  components: {
    HeaderLogin,
    Textarea,
    MenuList,
    GameList,
  },
  computed: {
    ...mapState({
      username: state => state.user.username || 'Guest'
    })
  }
};
</script>

<style scoped>
.main-container {
  padding-top: 72px;
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}
</style>
