<template>
    <div>
      <Header />
      <h1>ทายบอล</h1>
    </div>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  
  export default {
    name: "FootballView",
    components: {
      Header,
    },
  };
  </script>
  