<template>
  <div class="grid grid-cols-4 gap-2 p-2 max-w-full md:max-w-[670px] mx-auto">
    <div 
      v-for="(item, index) in menuItems" 
      :key="index" 
      :class="[
        'flex flex-col items-center rounded-lg shadow p-0',
        item.isInactive ? 'bg-blue-800 cursor-not-allowed' : 'bg-gradient-to-br from-blue-700 to-blue-500'
      ]" 
      @click="handleClick(item)">
      <div class="flex flex-col items-center cursor-pointer">
        <div class="relative">
          <img :src="item.icon" alt="icon" class="w-12 h-12 mb-2 p-1" />
          <span 
            v-if="item.badge" 
            class="absolute top-0 right-0 text-white text-xs font-bold rounded px-2 py-0.5">
            {{ item.badge }}
          </span>
        </div>
        <span class="text-center text-white font-medium text-sm mb-2">
          {{ item.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import apiPath from "@/config/apiPath";
import { mapState } from "vuex";

export default {
  name: "MenuList",
  data() {
    return {
      menuItems: [], // Store menu items fetched from API
    };
  },
  computed: {
    ...mapState(["isLoggedIn"]), // Retrieve login state from Vuex store
  },
  mounted() {
    this.fetchMenuItems(); // Fetch menu items when the component is mounted
  },
  methods: {
    async fetchMenuItems() {
      try {
        // Fetch menu items from the API
        const response = await axios.get(apiPath.GET_MENUS);
        this.menuItems = response.data.sort((a, b) => a.order - b.order); // Sort menu items by order
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    },
    handleClick(item) {
      if (!this.isLoggedIn) {
        // Redirect to login if the user is not logged in
        this.showToast("กรุณาเข้าสู่ระบบก่อนเล่นกิจกรรม");
        this.$router.push("/login");
        return;
      }

      if (!item.isInactive) {
        // Navigate to the item's route if it's active
        if (item.route) {
          this.$router.push(item.route);
        } else {
          this.showToast("ไม่มีเส้นทางสำหรับเมนูนี้");
        }
      } else {
        // Show a message if the menu is inactive
        this.showToast("กิจกรรมยังไม่เริ่ม ลูกค้ารอเล่นได้เลยนะคะ เร็วๆ นี้");
      }
    },
    showToast(message) {
      // Display a toast notification
      this.$toast.open({
        message,
        type: "info",
        position: "bottom-left",
        duration: 5000,
        style: {
          backgroundColor: "#ffc107",
          color: "black",
        },
        className: "custom-toast",
      });
    },
  },
};
</script>

<style scoped>
/* Styling for menu items */
.bg-gradient-to-br {
  background: linear-gradient(135deg, #172f82, #180650);
  border: 1px solid #EECE61;
}

/* Adjust the width and height of toast notifications */
.custom-toast .v-toast__item {
  max-width: 200px;
  height: 100px;
  padding: 10px 15px;
  font-size: 14px;
}
</style>
