// store/index.ts
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import apiPath from "@/config/apiPath";
import axios from "axios";

const store = createStore({
  state: {
    user: {
      username: '',
      phoneNumber: '',
      userId: '', // เพิ่ม userId
      countGuess: 5,
    },
    lotteryHistory: [],
    isLoggedIn: false,
  },
  mutations: {
    setUser(state, userData) {
      state.user = {
        ...userData,
      };
      state.isLoggedIn = true;
      // userId ตอนนี้จะเป็น ObjectId ที่ได้รับจากฐานข้อมูล MongoDB
      state.user.userId = userData.userId || ''; // เพิ่มการตั้งค่า userId ที่เป็น _id
    },
    clearUser(state) {
      state.user = { username: '', phoneNumber: '', userId: '', countGuess: 5 }; // รีเซ็ต userId ด้วย
      state.isLoggedIn = false;
    },
    setLotteryHistory(state, history) {
      state.lotteryHistory = history;
    },
    decrementCountGuess(state) {
      if (state.user.countGuess > 0) {
        state.user.countGuess -= 1;
      }
    },
    resetCountGuess(state) {
      state.user.countGuess = 5;
    },
    setCountGuess(state, count) {
      state.user.countGuess = count;
    },
  },
  actions: {
    async fetchUserCountGuess({ commit, state }) {
      try {
        if (!state.user.userId) {
          throw new Error("User ID is not available");
        }
        const response = await axios.get(apiPath.GET_USER_LOTTERY_DATA(state.user.userId));
        const countGuess = response.data.countGuess || 5;
        commit('setCountGuess', countGuess);
      } catch (error) {
        console.error("Error fetching user's countGuess:", error);
      }
    },
    async fetchLotteryHistory({ commit, state }) {
      try {
        if (!state.user.userId) {
          throw new Error("User ID is not available");
        }
        const response = await axios.get(apiPath.GET_LOTTERY_HISTORY(state.user.userId));
        if (!Array.isArray(response.data) || response.data.length === 0) {
          commit("setLotteryHistory", []);
          return;
        }

        const history = response.data.map((item) => ({
          dateGuess: item.dateGuess
            ? new Date(item.dateGuess).toISOString().split("T")[0]
            : "-",
          number: item.number || "",
          correctNumbers: Array.isArray(item.correctNumbers) ? item.correctNumbers : [],
          status: item.status || "รอผล",
          credit: item.credit || 0,
        }));

        commit("setLotteryHistory", history);
      } catch (error) {
        commit("setLotteryHistory", []);
        console.error("Error fetching lottery history:", error);
      }
    },
  },
  plugins: [
    createPersistedState({
      paths: ['user', 'isLoggedIn', 'lotteryHistory'],
    }),
  ],
});

export default store;
