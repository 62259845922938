<template>
  <div id="app">
    <router-view /> <!-- ใช้ router-view เพื่อแสดง layout หรือ view -->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
