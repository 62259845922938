import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import LotteryView from "@/views/LotteryView.vue";
import FootballView from "@/views/FootballView.vue";
import BoxingView from "@/views/BoxingView.vue"; // เพิ่ม BoxingView
import SlotsView from "@/views/SlotsView.vue";
import BaccaratView from "@/views/BaccaratView.vue";
import CheckInView from "@/views/CheckInView.vue";
import GameDetail from "@/components/GameDetail.vue";
import Login from '@/views/Login.vue';
import FootballDetail from "@/views/FootballDetail.vue";
import FootballHistory from "@/views/FootballHistory.vue";
import BoxingDetail from "@/views/BoxingDetail.vue"; // เพิ่ม BoxingDetail
import BoxingHistory from "@/views/BoxingHistory.vue"; // เพิ่ม BoxingHistory
import SlotHistory from "@/views/SlotHistory.vue"; // นำเข้า SlotHistory.vue
import BaccaratHistory from '@/views/BaccaratHistory.vue';
import Birthday from '@/views/Birthday.vue';
import Gold from "@/views/Gold.vue";
import MainLayoutAfterlogin from "@/layouts/MainLayoutAfterlogin.vue"
import LotteryHistory from '@/views/LotteryHistory.vue';
import { RouteLocationNormalized } from 'vue-router';


const routes = [
  {
    path: "/",
    component: MainLayout,
  },
  {
    path: '/maruay61',
    name: 'MainLayoutAfterlogin',  // ตรวจสอบชื่อให้ตรงกัน
    component: MainLayoutAfterlogin,
    props: (route: RouteLocationNormalized) => ({
      username: route.query.username as string || 'Guest'  // ใช้ default 'Guest' ถ้าไม่มี username
    }),
  },

  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
  },
  {
    path: "/lottery",
    component: LotteryView,
  },
  {
    path: "/football",
    name: "FootballView",
    component: FootballView,
  },
  {
    path: "/football-detail",
    name: "FootballDetail",
    component: FootballDetail,
    props: true,
  },
  {
    path: "/football-history",
    name: "FootballHistory",
    component: FootballHistory,
  },
  {
    path: "/boxing",
    name: "BoxingView", // ตั้งชื่อ BoxingView
    component: BoxingView, // ใช้ BoxingView
  },
  {
    path: "/boxing-detail",
    name: "BoxingDetail", // ตั้งชื่อ BoxingDetail
    component: BoxingDetail, // ใช้ BoxingDetail
    props: true, // เปิดการส่ง props ผ่าน route params
  },
  {
    path: "/boxing-history",
    name: "BoxingHistory", // ตั้งชื่อ BoxingHistory
    component: BoxingHistory, // ใช้ BoxingHistory
  },
  {
    path: "/slots",
    component: SlotsView,
  },
  {
    path: "/baccarat",
    component: BaccaratView,
  },
  {
    path: "/checkin",
    component: CheckInView,
  },
  {
    path: "/game-detail/:gameName/:gameImage/:gameProvider", // เพิ่มพารามิเตอร์ gameProvider
    name: "game-detail",
    component: GameDetail,
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: "/slot-history",
    name: "SlotHistory", // 
    component: SlotHistory,
  },
  {
    path: '/baccarat-history',
    name: 'BaccaratHistory',
    component: BaccaratHistory,
  },
  {
    path: '/birthday',
    name: 'Birthday',
    component: Birthday,
  },
  {
    path: '/gold',
    name: 'gold',
    component: Gold,
  },
  {
    path: '/lottery-history',
    name: 'LotteryHistory', // ตั้งชื่อเส้นทาง
    component: LotteryHistory, // นำเข้า component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



export default router;
