<template>
    <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
      <HeaderLogin />
      <BtnBack />


      <h1 class="text-2xl text-white font-bold text-center mb-4">ประวัติสุ่มเล่นสล็อต</h1>
  
      <!-- ประวัติการสุ่มเล่นสล็อต -->
      <div class="overflow-x-auto">
        <table class="w-full bg-[#171e79] text-white p-4 rounded-lg table-auto border-collapse">
          <thead class="bg-yellow-400 text-black">
            <tr>
              <th class="px-4 py-2 text-left">ชื่อเกม</th>
              <th class="px-4 py-2 text-left">ค่ายเกม</th>
              <th class="px-4 py-2 text-left">เครดิตเพิ่ม</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(history, index) in historyList" :key="index" class="border-b border-yellow-500">
              <td class="px-4 py-2">{{ history.name }}</td>
              <td class="px-4 py-2">{{ history.provider }}</td>
              <td class="px-4 py-2">{{ history.credit }} บาท</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>

import BtnBack from "@/components/BtnBack.vue";
import HeaderLogin from "@/components/HeaderLogin.vue";
  
  export default {
    name: "SlotHistory",
    components: {
      HeaderLogin,
      BtnBack
    },
    data() {
      return {
        historyList: [
          { name: "Shark Hunter", provider: "PG", credit: 100 },
          { name: "Yaksa of Honor", provider: "PG", credit: 50 },
          { name: "Shark Hunter", provider: "PG", credit: 200 },
        ],
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style scoped>
  .material-icons {
    font-size: 24px;
  }
  
  /* สไตล์สำหรับตาราง */
  table {
    border-spacing: 0;
  }
  
  th, td {
    border-bottom: 1px solid #ffdd57;
  }
  
  th {
    font-weight: bold;
    text-transform: uppercase;
    background-color: #ffdd57;
  }
  
  td {
    text-align: left;
    padding: 12px 16px;
  }
  
  tr:hover {
    background-color: #1e2a7a;
  }
  </style>
  