<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <HeaderLogin />
    <BtnBack />
    <h1 class="text-2xl text-white font-bold text-center mb-4">
      ประวัติทายหวย
    </h1>

    <div class="overflow-x-auto">
      <table class="w-full bg-[#171e79] text-white p-4 rounded-lg table-auto border-collapse">
        <thead class="bg-yellow-400 text-black">
          <tr>
            <th class="px-2 py-1 text-left whitespace-nowrap">วันที่ทาย</th>
            <th class="px-2 py-1 text-left whitespace-nowrap">เลขทายหวย</th>
            <th class="px-2 py-1 text-left whitespace-nowrap">สถานะ</th>
            <th class="px-2 py-1 text-left whitespace-nowrap">เครดิตที่ได้รับ</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(entry, index) in processedLotteryHistory" :key="`${index}-${entry.number}`"
            class="border-b border-yellow-500">
            <td class="px-4 py-2">{{ entry.dateGuess }}</td> <!-- แสดงวันที่ -->
            <td class="px-4 py-2">{{ entry.number }}</td>
            <td :class="{
              'text-green-500': entry.status === 'ถูก',
              'text-red-500': entry.status === 'ไม่ถูก',
              'text-yellow-500': entry.status === 'รอผล',
            }" class="px-4 py-2">
              {{ entry.status }}
            </td>
            <td class="px-4 py-2">{{ entry.credit }} บาท</td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import HeaderLogin from "@/components/HeaderLogin.vue";
import BtnBack from "@/components/BtnBack.vue";

export default {
  name: "LotteryHistory",
  components: {
    HeaderLogin,
    BtnBack,
  },
  computed: {
    ...mapState({
      user: (state) => state.user, // ดึงข้อมูลผู้ใช้จาก Vuex Store
      lotteryHistory: (state) => state.lotteryHistory, // ดึงประวัติการทายหวยจาก Vuex Store
    }),
    processedLotteryHistory() {
      if (!Array.isArray(this.lotteryHistory) || this.lotteryHistory.length === 0) {
        return [];
      }

      return this.lotteryHistory.flatMap((history) => {
        const numbers = history.number ? history.number.split(",").map((num) => num.trim()) : [];
        const correctNumbers = Array.isArray(history.correctNumbers) ? history.correctNumbers : [];
        const hasWinningNumber = !!history.correctNumbers?.length;

        return numbers.map((num) => ({
          dateGuess: history.dateGuess || "-", // เพิ่มวันที่
          number: num,
          status: !hasWinningNumber ? "รอผล" : correctNumbers.includes(num) ? "ถูก" : "ไม่ถูก",
          credit: correctNumbers.includes(num) ? history.credit : 0,
        }));
      });
    },
  },
  async mounted() {
    try {
      if (!this.user.username) {
        throw new Error("Username not found. Please log in again.");
      }

      // เรียก Action เพื่อดึงข้อมูลประวัติการทายหวย
      await this.fetchLotteryHistory();
    } catch (error) {
      console.error("Error fetching lottery history:", error.message);
    }
  },
  methods: {
    ...mapActions(["fetchLotteryHistory"]), // ใช้ Action fetchLotteryHistory
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}

/* เพิ่มสไตล์สำหรับตาราง */
table {
  border-spacing: 0;
}

th,
td {
  border-bottom: 1px solid #ffdd57;
}

th {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ffdd57;
}

td {
  text-align: left;
  padding: 12px 16px;
}

tr:hover {
  background-color: #1e2a7a;
}
</style>
