// config/axios.js
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // ดึงค่า URL หลักจาก .env
    timeout: 5000, // กำหนด timeout (หน่วยเป็น ms)
    headers: {
        "Content-Type": "application/json"
    }
});

export default axiosInstance;
