<template>
    <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
      <HeaderLogin />
      <BtnBack />

      <!-- ข้อมูลการแข่งขัน -->
      <div v-if="dataLoaded" class="bg-[#171e79] text-white p-4 rounded-lg mb-4 border border-yellow-400">
        <h1 class="text-center text-2xl font-bold mb-4">{{ league }}</h1>
  
        <!-- นักมวยและเวลาการแข่ง -->
        <div class="flex justify-between items-center mb-4">
          <div class="text-center">
            <img v-if="fighter1Logo" :src="getLogoPath(fighter1Logo)" alt="Fighter 1 Logo" class="w-16 h-16 mx-auto mb-2" />
            <p>{{ fighter1 }}</p>
          </div>
  
          <div class="text-center text-yellow-400">
            <p>{{ date }}</p>
            <p class="text-2xl font-bold">{{ time }}</p>
          </div>
  
          <div class="text-center">
            <img v-if="fighter2Logo" :src="getLogoPath(fighter2Logo)" alt="Fighter 2 Logo" class="w-16 h-16 mx-auto mb-2" />
            <p>{{ fighter2 }}</p>
          </div>
        </div>
  
        <!-- รูปสังเวียนมวย -->
        <div class="text-center">
          <img src="/assets/ImagesBoxing/ring.png" alt="Boxing Ring" class="w-64 h-auto mx-auto mb-4" />
        </div>
  
        <!-- เลือกนักมวยทายผล -->
        <h2 class="text-center text-lg font-bold mb-4">เลือกนักมวยทายผล</h2>
        <div class="flex justify-center mb-4">
          <button :class="{'bg-yellow-400': selectedFighter === fighter1, 'bg-gray-400': selectedFighter !== fighter1}"
                  class="text-black px-6 py-2 rounded-lg font-bold text-sm mx-2"
                  @click="selectFighter(fighter1)">
            {{ fighter1 }}
          </button>
          <button :class="{'bg-yellow-400': selectedFighter === fighter2, 'bg-gray-400': selectedFighter !== fighter2}"
                  class="text-black px-6 py-2 rounded-lg font-bold text-sm mx-2"
                  @click="selectFighter(fighter2)">
            {{ fighter2 }}
          </button>
        </div>
  
        <!-- ปุ่มทายมวย -->
        <div class="text-center">
          <button @click="submitPrediction" class="bg-yellow-400 text-black px-6 py-2 rounded-lg font-bold text-lg">
            ทายมวย
          </button>
        </div>
  
        <!-- แจ้งเตือน -->
        <div v-if="notification" class="text-center text-green-500 mt-4">
          {{ notification }}
        </div>
      </div>
  
      <!-- แสดงข้อความเมื่อไม่สามารถโหลดข้อมูลได้ -->
      <div v-else class="text-center text-white">
        <h2>ไม่สามารถโหลดข้อมูลได้</h2>
      </div>
  
      <!-- เงื่อนไขการเข้าร่วมกิจกรรม Warning -->
      <div class="bg-[#fff5e4] text-[#815b2a] p-4 mt-6 rounded-lg">
        <h3 class="text-lg font-bold mb-2">
          เงื่อนไขการเข้าร่วมกิจกรรม รับเครดิตจากการทายมวย
        </h3>
        <ul class="list-decimal list-inside text-sm">
          <li>ต้องฝากเงินขั้นต่ำ 500 บาท เพื่อมีสิทธิ์ทายผล</li>
          <li>ฝาก 500 บาท ได้ 1 สิทธิ์</li>
          <li>ฝาก 1,000 บาท ได้ 2 สิทธิ์ในการทายผล 2 คู่</li>
          <li>รางวัลเป็นเครดิตทำเทิร์น 1 เท่า ถอนได้ไม่อั้น*</li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
import BtnBack from "@/components/BtnBack.vue";
import HeaderLogin from "@/components/HeaderLogin.vue"
  
  export default {
    name: "BoxingDetail",
    components: {
      HeaderLogin,
      BtnBack,
    },
    data() {
      let match = null;
      try {
        match = JSON.parse(localStorage.getItem('selectedMatch'));
      } catch (error) {
        console.error("Error loading match data from localStorage:", error);
      }
  
      return {
        dataLoaded: match !== null,
        league: match?.league || 'ไม่ทราบลีก',
        date: match?.date || 'ไม่ทราบวันที่',
        time: match?.time || 'ไม่ทราบเวลา',
        fighter1: match?.fighter1 || 'ไม่ทราบนักมวย 1',
        fighter2: match?.fighter2 || 'ไม่ทราบนักมวย 2',
        fighter1Logo: match?.fighter1Logo || null,
        fighter2Logo: match?.fighter2Logo || null,
        selectedFighter: null, // เก็บข้อมูลนักมวยที่ผู้ใช้เลือก
        notification: null, // สำหรับแสดงข้อความแจ้งเตือน
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      getLogoPath(logo) {
        return logo ? `/assets/ImagesBoxing/${logo}` : '';
      },
      selectFighter(fighter) {
        this.selectedFighter = fighter;
      },
      submitPrediction() {
        if (this.selectedFighter) {
          this.notification = `คุณได้เลือกนักมวย ${this.selectedFighter} ในการทายผล`;
        } else {
          this.notification = 'กรุณาเลือกนักมวยก่อนทายผล';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .material-icons {
    font-size: 24px;
  }
  </style>
  