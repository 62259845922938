<template>
    <button @click="goBack" class="bg-yellow-400 text-black px-4 py-3 rounded-full flex items-center  mb-4 mt-10">
      <span class="material-icons">arrow_back</span> ย้อนกลับ
    </button>
  </template>
  
  <script>
  export default {
    methods: {
      goBack() {
        this.$router.go(-1); // กลับไปหน้าก่อนหน้า
      },
    },
  };
  </script>
  
  <style scoped>
  .material-icons {
    font-size: 24px;
  }
  </style>
  