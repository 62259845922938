<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <HeaderLogin />
      <BtnBack /> 
    <!-- SliderFootballView -->
    <SliderFootballView />

    <!-- รายการทีมแข่งที่มีไอคอน fire.gif พร้อมปุ่มลิงก์ไปยังประวัติทายผลบอล -->
    <div class="flex items-end justify-between mb-4 mt-5">
      <div class="flex items-center">
        <img src="/assets/icons/fire.gif" alt="fire icon" class="w-8 h-8 mr-2" />
        <h1 class="text-2xl font-bold text-white">รายการทีมแข่ง</h1>
      </div>

      <!-- ปุ่มลิงก์ไปยังหน้า FootballHistory.vue พร้อมไอคอน -->
      <button
        @click="goToHistory"
        class="text-white text-sm font-bold flex items-center hover:underline"
      >
        <span class="material-icons mr-2">history</span> <!-- ใช้ไอคอนจาก Material Icons -->
        ประวัติทายผลบอล
      </button>
    </div>

    <!-- Tabs ที่สามารถ Scroll ซ้ายขวาได้ -->
    <div class="flex overflow-x-auto whitespace-nowrap mb-4 space-x-4">
      <button class="px-4 py-2 bg-white text-black rounded-lg">
        Premier League
      </button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">
        Thai League
      </button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">La Liga</button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">
        Bundesliga
      </button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">Serie A</button>
      <button class="px-4 py-2 bg-white text-black rounded-lg">Ligue 1</button>
    </div>

    <!-- รายการทายบอล -->
    <div
      v-for="(match, index) in matchList"
      :key="index"
      class="bg-[#171e79] text-white p-4 rounded-lg mb-4 border border-yellow-400"
    >
      <h2 class="text-center text-lg font-bold mb-2">{{ match.league }}</h2>

      <!-- ทีมและเวลาการแข่ง -->
      <div class="flex justify-between items-center mb-4">
        <div class="text-center">
          <img
            :src="getLogoPath(match.team1Logo)"
            alt="Team 1 Logo"
            class="w-16 h-16 mx-auto mb-2"
          />
          <p>{{ match.team1 }}</p>
        </div>

        <div class="text-center text-yellow-400">
          <p>{{ match.date }}</p>
          <p class="text-2xl font-bold">{{ match.time }}</p>
        </div>

        <div class="text-center">
          <img
            :src="getLogoPath(match.team2Logo)"
            alt="Team 2 Logo"
            class="w-16 h-16 mx-auto mb-2"
          />
          <p>{{ match.team2 }}</p>
        </div>
      </div>

      <!-- ปุ่มทายผลบอล -->
      <div class="text-center flex justify-center">
        <button
          @click="goToDetail(match)"
          class="bg-yellow-400 text-black px-6 py-2 rounded-lg font-bold text-lg flex items-center justify-center"
        >
          <img
            src="/assets/icons/play-icon.png"
            alt="play icon"
            class="w-5 h-5 mr-2"
          />
          ทายผลบอล
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogin from "@/components/HeaderLogin.vue";
import SliderFootballView from "@/components/SliderFootballView.vue";
import BtnBack from "@/components/BtnBack.vue"; 

export default {
  name: "FootballView",
  components: {
    HeaderLogin,
    SliderFootballView,
    BtnBack,
  },
  data() {
    return {
      matchList: [
        {
          league: "Campaign League",
          date: "17/10/2024",
          time: "02:00",
          team1: "Real Madrid",
          team2: "Barcelona",
          team1Logo: "real-madrid-logo.png", // ตรวจสอบชื่อไฟล์
          team2Logo: "barcelona-logo.png", // ตรวจสอบชื่อไฟล์
        },
        {
          league: "Campaign League",
          date: "18/10/2024",
          time: "04:00",
          team1: "Chelsea",
          team2: "Man Utd",
          team1Logo: "chelsea-logo.png", // ตรวจสอบชื่อไฟล์
          team2Logo: "man-utd-logo.png", // ตรวจสอบชื่อไฟล์
        },
      ],
    };
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToDetail(match) {
      // บันทึกข้อมูลลงใน localStorage
      localStorage.setItem("selectedMatch", JSON.stringify(match));

      // นำทางไปยังหน้า FootballDetail
      this.$router.push({ name: "FootballDetail" });
    },
    goToHistory() {
      // นำทางไปยังหน้า FootballHistory
      this.$router.push({ name: "FootballHistory" });
    },
    getLogoPath(logo) {
      // ใช้เส้นทางแบบ static path จาก public directory
      return `/assets/logoTeam/${logo}`;
    },
  },
};
</script>

<style scoped>
.material-icons {
  font-size: 24px;
}
</style>
