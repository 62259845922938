<template>
  <div class="pb-16 overflow-y-auto md:max-w-[670px] mx-auto pt-16 p-4">
    <HeaderLogin />
    <BtnBack />

    <!-- Ranking Section -->
    <div class="ranking-section bg-blue-100 p-4 rounded-lg shadow-lg mt-6">
      <h2 class="text-center text-xl text-white font-bold">
        10 อันดับเติมเครดิตสูงสุด
      </h2>
      <h3 class="text-center text-xl text-yellow-500 font-bold mb-2">
        ลุ้นรางวัลสิ้นปี
      </h3>
      <div class="flex items-center justify-center mb-3">
        <div class="w-24 h-24 flex items-center justify-center">
          <img src="/assets/icons/podium_9084166.png" alt="User Profile" class="object-cover w-20 h-20 " />
        </div>
      </div>
      
      <p class=" rank-span text-center text-xl font-semibold text-white mb-3">
        {{ username }} :
        <span v-if="getUserRank()">
          อันดับ {{ getUserRank() }}
        </span>
        <span v-else>ไม่ติดอันดับ</span>
      </p>
      
      <!-- User Ranking List -->
      <ul class="space-y-2">
        <li v-for="(user, index) in users" :key="index"
          class="flex items-center justify-between bg-blue-800 p-2 rounded-lg shadow-md">
          <div class="flex items-center space-x-2">
            <img src="/assets/icons/profile2.png" alt="User Profile" class="profile-image" />
            <div>
              <p class="font-semibold text-white">{{ user.username }}</p>
              <p class="text-sm text-yellow-500">
                ยอดฝากรวม: {{ formatNumber(user.credit) }}
              </p>
              <p class="text-sm text-black font-bold bg-green-400 px-2 py-1 rounded">
                รางวัล: {{ formatNumber(user.CreditForRank) }} เครดิต
              </p>
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <img :src="getLevelIcon(user.idRank)" alt="Level Icon" class="level-icon" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BtnBack from "@/components/BtnBack.vue";
import HeaderLogin from "@/components/HeaderLogin.vue";
import API_PATH from "@/config/apiPath"; // Import API Path
import axiosInstance from "@/config/axios"; // Import Axios Instance

export default {
  name: "PromotionNewYear",
  components: {
    HeaderLogin,
    BtnBack,
  },
  computed: {
    ...mapState({
      username: (state) => state.user.username, // ดึง username จาก Vuex Store
    }),
  },
  data() {
    return {
      users: [], // เก็บข้อมูลอันดับจาก API
    };
  },
  methods: {
    async fetchRankings() {
      try {
        const response = await axiosInstance.get(API_PATH.GET_RANKINGS); // ใช้ axiosInstance กับ API_PATH
        this.users = response.data; // เก็บข้อมูลอันดับใน users
      } catch (error) {
        console.error("Failed to fetch rankings:", error);
      }
    },
    getLevelIcon(rank) {
      return `/assets/level-icons/level${rank}.png`; // ดึงไอคอนของอันดับ
    },
    getUserRank() {
      const userIndex = this.users.findIndex(
        (user) => user.username === this.username
      );
      return userIndex !== -1 ? userIndex + 1 : null; // หากตรงกันจะคืนค่าลำดับ
    },
    formatNumber(value) {
      // แสดงตัวเลขด้วยจุดทศนิยมและคั่นหลักพัน
      return value.toLocaleString("en-US", { minimumFractionDigits: 0 });
    },
  },
  created() {
    this.fetchRankings(); // ดึงข้อมูลเมื่อโหลดหน้า
  },
};
</script>

<style scoped>
.ranking-section {
  background: linear-gradient(to bottom, #172f82, #180650);
  border: 1px solid #EECE61;
}

img {
  display: block;
}

/* เพิ่มขนาดรูปโปรไฟล์ */
.ranking-section img.profile-image {
  width: 50px;
  /* ปรับขนาดความกว้าง */
  height: 50px;
  /* ปรับขนาดความสูง */
}

/* เพิ่มขนาดไอคอนระดับ */
.ranking-section img.level-icon {
  width: 60px;
  height: 60px;
}

/* ปรับขนาดและจัดตำแหน่งรูปใน Border */
.ranking-section .w-24.h-24 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* Custom Background for Rank Status */
.bg-green-600 {
  background-color: #16a34a;
}

.bg-red-600 {
  background-color: #dc2626;
}

.bg-green-700 {
  background-color: #15803d;
}

.rank-span {
  background: linear-gradient(135deg, #172f82, #180650);
  border: 1px solid #FFD700;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
}
</style>
